import { createApp } from "vue";
import { ConfigProvider } from "vant";
import { createPinia } from "pinia";
import App from "./App.vue";
// import "@frontend/pigeon/dist/browser.min.js";

import "./font/index.scss";
import router from "./router";
import "./sensors";

import "vant/lib/index.css";
import "@frontend/du-styles/styles/index.scss";
import "@frontend/dumpling/dist/style.css";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { init } from "@frontend/eros/dist/web";

init();

const pinia = createPinia();

createApp(App).use(router).use(ConfigProvider).use(pinia).mount("#app");
