<template>
  <!--  <van-config-provider theme="dark"  :theme-vars="themeVars">-->
  <van-config-provider>
    <van-sticky>
      <!--      :title="title"-->
      <!--      <van-nav-bar left-arrow @click-left="onClickLeft">-->
      <!--        &lt;!&ndash;        <template #left>&ndash;&gt;-->
      <!--        &lt;!&ndash;          <van-image&ndash;&gt;-->
      <!--        &lt;!&ndash;            fit="cover"&ndash;&gt;-->
      <!--        &lt;!&ndash;            class="back-img"&ndash;&gt;-->
      <!--        &lt;!&ndash;            src="https://cdn.qiandaoapp.com/ued/dd361cf3cdc244a1dd1feca5fc4468f7.png!mfit_w480_h480_jpg"&ndash;&gt;-->
      <!--        &lt;!&ndash;            alt=""&ndash;&gt;-->
      <!--        &lt;!&ndash;          />&ndash;&gt;-->
      <!--        &lt;!&ndash;        </template>&ndash;&gt;-->
      <!--      </van-nav-bar>-->
    </van-sticky>
    <router-view />
  </van-config-provider>
</template>

<script setup>
import { ref } from "vue";
import { NavBar as VanNavBar, Sticky as VanSticky } from "vant";
import { useRouter } from "vue-router";
import { Image as VanImage } from "vant";

const router = useRouter();

// const title = ref(document.title)

const onClickLeft = () => {
  router.back();
};

// import { px2rem } from '@/utils/rem'
// import {ref} from 'vue'

// const testSize = ref(px2rem(32))

// console.log('px2rem', px2rem(32));

const themeVars = {
  // buttonPrimaryBackground: 'linear-gradient(150.58deg, #2B1AC0 0%, #7247DC 100%)',
  // buttonDefaultFontSize: px2rem(32),
  // buttonBorderWidth: px2rem(0),
  // buttonRadius: px2rem(16),
  // checkboxSize: px2rem(28),
  // checkboxCheckedIconColor: '#2B1AC0',
};
</script>

<style lang="scss">
:root:root {
  //--van-nav-bar-background: transparent;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

body {
  padding: 0;
}

.mobLink-wrapper {
  display: none;
}

.back-img {
  width: 88rpx;
  height: 88rpx;
}

.otext2 {
  display: -webkit-box;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

//:root {
//  --van-white: red;
//  --van-blue: red;
//  --van-button-primary-color: var(--van-white);
//  --van-button-primary-background: var(--van-primary-color);
//}
</style>
